import React from 'react';
import SignIn from "./screens/Auth/SignIn";

function App() {
  return (
    <SignIn></SignIn>
  );
}

export default App;
