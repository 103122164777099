import './styles.css'

import React from "react";

export default ({children}) => {
  return (
    <div className="container">
      {children}
    </div>
  )
}