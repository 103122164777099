import './styles.css'

import React from "react";
import Coffecup from "./coffee-cup.svg";

export default () => {
  return (
    <div className="box">
    <div className="content-container">
      <img src={Coffecup} alt="Coffee cup"/>
    </div>
  </div>
  )
}