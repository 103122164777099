import './styles.css'

import React from "react";

export default () => {
  return (
    <div className="box blue-background">
    <div className="content-container">
      <label class="login-label black">COFFEE TOOL</label>
      <h1 className="title">Take a break with your coworkers</h1>
      <p className="subtitle">The tool will allow you to meet your entire team, strengthen ties, and discover new things.</p>
      <button className="sign-in-button">
        <span className="text">sign in</span>
      </button>
    </div>
  </div>
  )
}