import LayoutComponents from '../../components/Layout'
import LoginComponents from '../../components/Login'
import React from "react";

export default () => {
  return (
    <LayoutComponents.TwoColumnGrid>
      <LoginComponents.LeftSide></LoginComponents.LeftSide>
      <LoginComponents.RightSide></LoginComponents.RightSide>
    </LayoutComponents.TwoColumnGrid>
  )
}